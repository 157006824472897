<template>
  <v-container class="contentContainer">
    <!-- PAGE BANNER - Feedback Title Section -->
    <PageHeader class="pb-4" heading="Disclaimers"/>
    <!-- MAIN CONTENT AREA - Content Section -->
    <v-row class="mt-0" justify="center">
      <v-col class="px-0 contentMaxWidth">
        <h2 class="pb-3">NOTICE OF NON-AFFILIATION</h2>
        <p>UofT Index is a project created by a UTSC student and is not affiliated, endorsed, or officially connected
          with the University of Toronto or related identities. The official UofT timetable is available at the
          following link:
          <a class="accent--text font-weight-medium" href="https://ttb.utoronto.ca/" target="_blank">Timetable</a></p>

        <h2 class="py-3">WEBSITE DATA</h2>
        <p>While we at UofT Index strive to ensure that the information and statistics provided on the site are as
          accurate and up to date as possible, we assume no liability for any errors or data inaccuracies. All website
          data should be used as a general guide.</p>
        <h4 class="mt-6 mb-2">Data Refresh Schedule</h4>
        <p>The directory and timetable data on this site are static. Meaning they are not continuously updated or
          pulled on-demand and may be out of date, especially timetable data that is frequently updated. However,
          during enrolment season, data refresh cycles will occur more frequently to allow for as up-to-date data as
          possible for users.</p>
        <p><strong>Please note </strong>that during a refresh process, data on the site may seem inconsistent or
          incomplete. This is expected and will resolve itself once the process is complete!</p>
        <v-sheet rounded outlined class="mb-8">
          <v-simple-table>
            <thead>
              <tr><th>TIMETABLE</th><th>DIRECTORY & DROP RATES</th><th>RATINGS</th></tr>
            </thead>
            <tbody>
            <tr>
              <td><strong>Daily</strong> • 12:00 AM - 12:10 AM EST</td>
              <td><strong>Weekly</strong> • Monday @ 12:00 AM - 12:30 AM EST</td>
              <td><strong>24/7</strong> • Live Data</td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-sheet>
        <h4 class="mb-2">Data Calculations & Privacy</h4>
        <p>Drop rate information is locally computed based on timetable data. The enrolment numbers at the course drop
          deadline are taken as the initial value, with subsequent refreshes until the last day of classes dictating
          the drop rates. The average across various filters is then taken based on what users are presently viewing.
          Please note every effort has been made to provide drop rates that are current and accurate. Nevertheless,
          inadvertent errors in information may occur. </p>
        <p>Course ratings are populated solely on user feedback and therefore rely on students to help provide it.
          Users can rate a maximum of 50 courses which is also the maximum number of courses that a student can
          favourite/save. Please note that, in the event users delete a previously saved course or their account,
          any and all associated ratings are also removed to prevent rating spam.</p>
        <p>ACORN academic history that users submit through the user dashboard to load courses is used exclusively
          for the purpose of extracting course codes. Grades, names, and any other information is irrelevant and
          ignored when parsing the data you provide. As a result, anything that users paste in that does not match a
          UofT course code structure (Ex. CSCA08H3, MAT130Y1, etc.) never leaves your device. </p>

        <h2 class="py-3">GOOGLE ANALYTICS</h2>
        <p>As pointed out on the UofT Index<strong class="accent--text" @click="$router.push('/privacy')"> Privacy Policy </strong>
          page, this website uses Google Analytics. To be transparent, besides the information that the service
          automatically collects, the only other information collected is the number of times a particular feature
          activates. In line with other analytics automatically collected, there is no username, email, UID, or
          identifiable markers associated with the data UofT Index intentionally collects, but rather a simple
          increment (+1) for a given feature.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from '@/components/PageHeader'

export default {
  name: 'Disclaimer',
  components: { PageHeader }
}
</script>

<style scoped>
  strong:hover {
    cursor: pointer;
  }

</style>
