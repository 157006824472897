import { render, staticRenderFns } from "./Disclaimer.vue?vue&type=template&id=11cbe272&scoped=true"
import script from "./Disclaimer.vue?vue&type=script&lang=js"
export * from "./Disclaimer.vue?vue&type=script&lang=js"
import style0 from "./Disclaimer.vue?vue&type=style&index=0&id=11cbe272&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11cbe272",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCol,VContainer,VRow,VSheet,VSimpleTable})
